/* Override the default dark theme colors */
.monaco-editor.vs-dark .token.parameter, .monaco-editor.vs-dark .token.variable, .monaco-editor.vs-dark .token.type, .monaco-editor.vs-dark .token.enum {
    color: #9CDCFE;
}
  
.monaco-editor.vs-dark .token.keyword {
  color: #C586C0;
}

.monaco-editor.vs-dark .token.number {
  color: #B5CEA8;
}

.monaco-editor.vs-dark .token.string {
  color: #CE9178;
}

.monaco-editor.vs-dark .token.comment {
  color: #6A9955;
}

.monaco-editor.vs-dark .token.function {
  color: #DCDCAA;
}
