.advanced-builder-grid {
    padding: 16px;
  }
  
  .canvas-grid-item,
  .code-editor-grid-item {
    height: 500px;
    position: relative;
  }
  
  .canvas-container,
  .code-editor-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .file-selector-grid-item {
    padding: 16px;
  }
  